import React from 'react';

import { Box, Divider, Heading, Text, VStack } from '@chakra-ui/react';

interface ComponentPreviewProps {
    title: string;
    render: boolean;
    children: React.ReactNode;
}

const ComponentPreview: React.FC<ComponentPreviewProps> = ({ title, render, children }) => {
    return (
        <Box
            w="full"
            h="full"
            borderWidth="2px"
            borderStyle="dashed"
            borderColor="gray.300"
            borderRadius="lg"
            p={4}
            pb={6}
            bg="transparent"
        >
            <Heading size="md" textAlign="left">{title}</Heading>
            <Divider mb={4} mt={2} />
            <VStack w="full" h="full" align="start" spacing={4}>
                {render ? children : <Text>Hiding for now</Text>}
            </VStack>
        </Box>
    );
};

export default ComponentPreview;
