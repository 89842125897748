import type { PathRouteProps } from "react-router-dom";

import HomePage from "../pages/home/HomePage";
import SopBuilderPage from "../pages/sop-builder/SopBuilderPage";

interface Props extends PathRouteProps {
  bodyPadding?: number[];
  bgColor?: string;
}

export const routes: Array<Props> = [];

export const privateRoutes: Array<Props> = [
  {
    path: "/",
    element: <HomePage />
  }
];
