import { useEffect, useState } from "react";

import { Stack } from "@chakra-ui/react";

import ListMultiSelect from "./ListMultiSelect";
import TextInput from "../TextInput";

interface OtherMultiSelectProps {
  selectedItems?: string[];
  defaultItems?: string[];
  items?: string[];
  onButtonClick: (item: string) => void;
  direction?: "row" | "column";
  value: string;
  onChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
  onAddClick?: () => void;
}

const OtherMultiSelect: React.FC<OtherMultiSelectProps> = ({ selectedItems, defaultItems, onButtonClick, value, onChange, onAddClick }) => {
  const [unselectedItems, setUnselectedItems] = useState<string[]>(defaultItems?.filter(item => !selectedItems?.includes(item)) || []);

  useEffect(() => {
    const remainingItems = defaultItems?.filter(item => !selectedItems?.includes(item)) || [];
    setUnselectedItems(remainingItems);
  }, [selectedItems]);

  const handleButtonClick = (item: string) => {
    onButtonClick(item);
  };

  return (
    <Stack
      direction="column"
      spacing={3}
      w="full"
      h="fit-content"
      flexWrap="wrap"
      ml={1}
      mr={1}
    >
      <ListMultiSelect
        selectedItems={[...(selectedItems || [])]}
        onButtonClick={handleButtonClick}
      />

      <TextInput
        showAdd={true}
        ml={1}
        mb={3}
        mt={1}
        value={value}
        onChange={onChange}
        onAddClick={onAddClick}
      />

      <ListMultiSelect
        defaultItems={[...(unselectedItems || [])]}
        onButtonClick={handleButtonClick}
      />
    </Stack>
  );
};

export default OtherMultiSelect;