import React from "react";

import { HStack, VStack } from "@chakra-ui/react";
import { Link, Outlet } from "react-router-dom";

const SopBuilderPage: React.FC = () => {
  return (
    <VStack w="full" h="100%" alignItems="stretch" overflow={"hidden"}>
      <HStack style={{ flex: 1 }}>
        <VStack style={{ width: "fit-content", height: "100%", alignItems: "flex-start" }}>
          <Link to="/sop-builder">Go to SOP Root</Link>
          <Link to="/sop-builder/section/page">Sample section/page</Link>
          <Link to="/sop-builder/section-two/page-one">Sample section-two/page-one</Link>
          <Link to="/sop-builder/section-three/page-two">Sample section-three/page-two</Link>
          <Link to="/sop-builder/section-no/not-yet">Sample section-no/not-yet</Link>
        </VStack>
        <VStack style={{ flex: 1, height: "100%" }}>
          <Outlet />
        </VStack>
      </HStack>
    </VStack>
  );
};

export default SopBuilderPage;
