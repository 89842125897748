import { useEffect, useState } from "react";

import { Stack } from "@chakra-ui/react";

import Button from "../Button";

interface ListMultiSelectProps {
  maintainOrder?: boolean;
  selectedItems?: string[];
  defaultItems?: string[];
  items?: string[];
  onButtonClick: (item: string) => void;
  direction?: "row" | "column";
}

const ListMultiSelect: React.FC<ListMultiSelectProps> = ({ direction = "row", selectedItems, defaultItems, onButtonClick }) => {
  const [items, setItems] = useState<string[]>([]);

  useEffect(() => {
    const remainingItems = defaultItems?.filter(item => !selectedItems?.includes(item)) || [];
    setItems([...(selectedItems || []), ...remainingItems]);
  }, [selectedItems, defaultItems]);

  return (
    <Stack
      direction={direction}
      spacing={3}
      w="full"
      h="fit-content"
      flexWrap="wrap"
      ml={1}
      mr={1}
    >
      {items?.map((name, index) => (
        <Button
          key={index}
          colorScheme={selectedItems?.includes(name) ? "blackAlpha" : "gray"}
          onClick={() => onButtonClick(name)}
          order={selectedItems?.includes(name) ? -1 : 0}
          iconType={selectedItems?.includes(name) ? "Remove" : "Add"}
          color={selectedItems?.includes(name) ? "darkslategray" : "gray"}
          whiteSpace={direction === "row" ? "nowrap" : "normal"}
        >
          {name}
        </Button>
      ))}
    </Stack>
  );
};

export default ListMultiSelect;