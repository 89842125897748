import React from "react";

import { Box, Button, Heading, Text, VStack } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";

const SopPage: React.FC = () => {
  const { section, page } = useParams();
  const navigate = useNavigate();

  const isIndex = !page;
  const notReady = page === "not-yet";

  if (isIndex) {
    return (
      <VStack w="full" h="100%" alignItems="stretch" overflow={"hidden"}>
        <Text>&lt;-- Click a link</Text>
      </VStack>
    );
  }

  if (notReady) {
    return (
      <VStack w="full" h="100%" alignItems="stretch" overflow={"hidden"}>
        <Text>This page isn't enabled yet - you go back</Text>
        <Button onClick={() => navigate(-1)} colorScheme="blue" w="fit-content">
          Go to Sop Builder
        </Button>
      </VStack>
    );
  }

  return (
    <VStack w="full" h="100%" alignItems="stretch" overflow={"hidden"}>
      <Heading>
        {section} / {page}
      </Heading>
    </VStack>
  );
};

export default SopPage;
