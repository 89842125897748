import { useState } from "react";

import { VStack, Text, Button, HStack } from "@chakra-ui/react";

import ComponentPreview from "../ComponentPreview";
import TextInput from "../TextInput";

export interface TextInputTesterProps {
    render: boolean;
}

export const TextInputTesterComponent = ({ render }: TextInputTesterProps) => {
    const [basicTextInputValue, setBasicTextInputValue] = useState("");
    const [phoneTextInputValue, setPhoneTextInputValue] = useState("");
    const [textInputToSave, setTextInputToSave] = useState("");
    const [textInputs, setTextInputs] = useState<string[]>([]);
    return (
        <ComponentPreview title="Text Input Area" render={render}>
            <VStack gap={10} w="full">
                <TextInput
                    value={basicTextInputValue}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setBasicTextInputValue(e.target.value)}
                    placeholder="Type anything..."
                />
                <TextInput
                    type="tel"
                    value={phoneTextInputValue}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const input = e.target.value.replace(/\D/g, '');
                        const formattedInput = input.replace(/(\d{3})(\d{1,3})?(\d{1,4})?/, (_, p1, p2, p3) => {
                            let result = p1;
                            if (p2) result += `-${p2}`;
                            if (p3) result += `-${p3}`;
                            return result;
                        });
                        setPhoneTextInputValue(formattedInput);
                    }}
                    placeholder="Type a phone number..."
                    maxLength={12}
                />
                <VStack w="full" alignItems="flex-start">
                    <TextInput
                        value={textInputToSave}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTextInputToSave(e.target.value)}
                        placeholder="Type something you want to keep!"
                        showAdd={true}
                        onAddClick={() => {
                            setTextInputs([...textInputs, textInputToSave]);
                            setTextInputToSave("");
                        }}
                    />
                    <HStack gap={2} justifyContent={"space-between"} w="full" mt={4}>
                        <HStack>
                            {textInputs.map((textInput, index) => (
                                <Text p={2} bg="gray.100" rounded="md" key={index}>{textInput}</Text>
                            ))}
                        </HStack>
                        <Button onClick={() => setTextInputs([])}>Clear</Button>
                    </HStack>
                </VStack>

            </VStack>
        </ComponentPreview>
    );
};