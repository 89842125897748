import { create } from "zustand";

import { UserInfo } from "../../../shared/models";

export interface Message {
  messageid: string;
  accountid: string;
  sender: string;
  message: string;
  datetime: string;
}

export interface Conversation {
  conversationid: number;
  messages: Message[];
}

interface AppState {
  userInfo?: UserInfo;
  filter: string;
  allConversations: Conversation[];
  filteredConversations: Conversation[];
  setUserInfo: (userInfo: UserInfo) => void;
  setFilter: (filter: string) => void;
  setAllConversations: (all: Conversation[]) => void;
  setFilteredConversations: (filtered: Conversation[]) => void;
}

export const useAppStore = create<AppState>()((set) => ({
  userInfo: undefined,
  filter: "",
  allConversations: [],
  filteredConversations: [],
  setUserInfo: (userInfo) => set(() => ({ userInfo })),
  setAllConversations: (all) => set(() => ({ allConversations: all })),
  setFilteredConversations: (filtered) => set(() => ({ filteredConversations: filtered })),
  setFilter: (filter) => set(() => ({ filter }))
}));
