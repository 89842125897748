import React, { useState } from 'react';

import { Heading, Text, TableContainer, Table, Thead, Tr, Th, Tbody, Td, Tfoot } from '@chakra-ui/react';

import BooleanOptionsList from './BooleanOptionsList';
import TextInput from './TextInput';

interface OptionItem {
    option: string;
    selected: boolean;
}

interface CustomItemWithDefinedOptionsItem {
    itemName: string;
    itemOptions: OptionItem[];
}

interface CustomItemWithDefinedOptionsProps {
    multiSelect: boolean;
    maintainOrder: boolean;
    headers: {
        itemType: string;
        optionsDescription: string;
    };
    items: CustomItemWithDefinedOptionsItem[];
    setItems: (items: CustomItemWithDefinedOptionsItem[]) => void;
    addItem: (item: string) => void;
}

const CustomItemWithDefinedOptions: React.FC<CustomItemWithDefinedOptionsProps> = ({ multiSelect, maintainOrder, headers, items, setItems = () => { }, addItem = () => { } }) => {
    const [inputValue, setInputValue] = useState('');

    const updateOptions = (item: CustomItemWithDefinedOptionsItem, selectedOption: string): OptionItem[] => {
        const selectedOptions = item.itemOptions.map(o => o.selected ? o.option : null).filter(o => o !== null);
        if (selectedOptions.includes(selectedOption)) {
            const updatedOptions: OptionItem[] = item.itemOptions.map(o => o.option === selectedOption ? { ...o, selected: !o.selected } : o);
            return updatedOptions;
        } else {
            if (multiSelect) {
                const updatedOptions: OptionItem[] = item.itemOptions.map(o => o.option === selectedOption ? { ...o, selected: true } : o);
                return updatedOptions;
            } else {
                const updatedOptions: OptionItem[] = item.itemOptions.map(o => o.option === selectedOption ? { ...o, selected: true } : { ...o, selected: false });
                return updatedOptions;
            }
        }
    }

    const handleOptionClick = (selectedOption: string, item: CustomItemWithDefinedOptionsItem) => {
        const newItems: CustomItemWithDefinedOptionsItem[] = [];

        items.forEach(i => {
            if (i.itemName === item.itemName) {
                newItems.push({ ...i, itemOptions: updateOptions(i, selectedOption) });
            } else {
                newItems.push(i);
            }
        });

        setItems(newItems);
    };

    const handleAddItem = () => {
        addItem(inputValue);
        setInputValue('');
    };


    return (
        <TableContainer width="100%">
            <Table variant='simple'>
                <Thead>
                    <Tr>
                        <Th width="25%">
                            <Heading size="sm" >{headers.itemType}</Heading>
                        </Th>
                        <Th width="75%">
                            <Heading size="sm" >{headers.optionsDescription}</Heading>
                        </Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {items.map((item, index) => {
                        return (
                            <Tr key={index}>
                                <Td width="25%">
                                    <Text>{item.itemName}</Text>
                                </Td>
                                <Td width="75%">
                                    <BooleanOptionsList
                                        maintainOrder={maintainOrder}
                                        selectedOptions={item.itemOptions.filter(o => o.selected).map(o => o.option)}
                                        allOptions={item.itemOptions.map(o => o.option)}
                                        onButtonClick={(selectedOption) => handleOptionClick(selectedOption, item)}
                                    />
                                </Td>
                            </Tr>
                        );
                    })}
                </Tbody>
                <Tfoot>
                    <Tr>
                        <Td colSpan={2}>
                            <TextInput
                                showAdd={true}
                                value={inputValue}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInputValue(e.target.value)}
                                onAddClick={handleAddItem}
                            />
                        </Td>
                    </Tr>
                </Tfoot>
            </Table>
        </TableContainer>

    );
};

export default CustomItemWithDefinedOptions;
