import { useState, useEffect } from "react";

import { Button, HStack } from "@chakra-ui/react";

import BooleanOptionsList from "../BooleanOptionsList";
import ComponentPreview from "../ComponentPreview";

export interface BooleanOptionsListTesterProps {
    render: boolean
}

const shortOptions = ["Option 1", "Option 2", "Option 3", "Option 4", "Option 5"];
const longOptions = [
    "This is a long option",
    "This is another long option",
    "This is the longest of the options availble",
    "This is a long option, but not the longest",
]

const shortOptionItems = shortOptions.map((option) => ({
    option: option,
    selected: false,
}));

export const BooleanOptionsListTester = ({ render }: BooleanOptionsListTesterProps) => {
    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
    const [defaultOptions, setDefaultOptions] = useState(shortOptionItems);
    const [maintainOrder, setMaintainOrder] = useState(false);
    const [direction, setDirection] = useState<"row" | "column">("row");
    const [isMultiSelect, setIsMultiSelect] = useState(false);
    const [useLongOptions, setUseLongOptions] = useState(false);

    useEffect(() => {
        if (!isMultiSelect && selectedOptions.length > 1) {
            setSelectedOptions(selectedOptions.slice(0, 1));
        }
    }, [isMultiSelect]);

    useEffect(() => {
        if (useLongOptions) {
            setDefaultOptions(longOptions.map((option) => ({
                option: option,
                selected: false,
            })));
            setSelectedOptions([])
        } else {
            setDefaultOptions(shortOptions.map((option) => ({
                option: option,
                selected: false,
            })));
            setSelectedOptions([])
        }
    }, [useLongOptions]);

    const handleButtonClick = (option: string) => {
        if (selectedOptions.includes(option)) {
            setSelectedOptions(selectedOptions.filter((o) => o !== option));
        } else {
            if (isMultiSelect) {
                setSelectedOptions([...selectedOptions, option]);
            } else {
                setSelectedOptions([option]);
            }
        }
    };

    return (
        <ComponentPreview title="Options List w/ Order, Direction, and MultiSelect Control" render={render}>
            <>
                <BooleanOptionsList
                    maintainOrder={maintainOrder}
                    direction={direction}
                    selectedOptions={selectedOptions}
                    allOptions={defaultOptions.map(o => o.option)}
                    onButtonClick={handleButtonClick}
                />
                <HStack w="full" justifyContent={'space-around'} mt={6}>
                    <Button onClick={() => setIsMultiSelect(!isMultiSelect)} p={2}>
                        MultiSelect is {isMultiSelect ? "On" : "Off"}
                    </Button>
                    <Button onClick={() => setMaintainOrder(!maintainOrder)} p={2}>
                        {maintainOrder ? "Enable" : "Disable"} Reordering
                    </Button>
                    <Button onClick={() => setDirection(direction === "row" ? "column" : "row")} p={2}>
                        Set Direction to {direction === "row" ? "Column" : "Row"}
                    </Button>
                    <Button onClick={() => setUseLongOptions(!useLongOptions)} p={2}>
                        Use {useLongOptions ? "Short" : "Long"} Options
                    </Button>
                </HStack>
            </>
        </ComponentPreview>
    );
};