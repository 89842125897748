import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import { UserInfo } from "../../../../shared/models";

async function getUserInfo(authId: string): Promise<UserInfo> {
  const response = await axios.get(`/api/user-info`, {
    params: { authId }
  });
  return response.data;
}

export function useUserInfo(authId: string) {
  return useQuery({
    queryKey: ["authId", authId],
    queryFn: () => getUserInfo(authId),
    enabled: !!authId
  });
}
