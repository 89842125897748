import { useEffect } from "react";

import { Box, ChakraProvider } from "@chakra-ui/react";
import { useUser } from "@clerk/clerk-react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter as Router } from "react-router-dom";

import { RichWindow } from "./globals";

import Routings from "~/router/Routings";
import { theme } from "~/styles/theme";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

const App = () => {
  const user = useUser();

  useEffect(() => {
    if (!user.isLoaded) return;

    if (user.isSignedIn) {
      RichWindow.heap.identify(user.user?.primaryEmailAddress?.emailAddress.toLocaleLowerCase());
    }
  }, [user.isSignedIn]);

  return (
    <QueryClientProvider client={queryClient}>
      <Box className="id--app">
        <ChakraProvider theme={theme} resetCSS={true}>
          <Router>
            <Routings />
          </Router>
        </ChakraProvider>
      </Box>
    </QueryClientProvider>
  );
};

export default App;
